import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'od-creation-flow',
  templateUrl: './creation-flow.component.html',
  styleUrls: ['./creation-flow.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreationFlowComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
