import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';

import * as fromActions from '../actions/account.actions';
import { AccountsService } from 'src/app/services';

@Injectable()
export class AccountEffects {
  constructor(private actions$: Actions, private service: AccountsService) {}

  @Effect()
  signUp$ = this.actions$.pipe(
    ofType(fromActions.ActionTypes.SignUp),
    map((action: fromActions.AccountSignUp) => action.payload),
    switchMap(({ account }) => {
      return this.service.signUp(account).pipe(
        map(({ message }) => {
          return new fromActions.AccountSignUpSuccess({ message });
        }),
        catchError(error => of(new fromActions.AccountSignUpFail({ error })))
      );
    })
  );
}
