import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';

export const getAccountMessage = createSelector(
  fromFeature.getAccountState,
  state => state && state.message
);

export const getAccountLoaded = createSelector(
  fromFeature.getAccountState,
  state => state && state.loaded
);

export const getAccountLoading = createSelector(
  fromFeature.getAccountState,
  state => state && state.loading
);

export const getAccountError = createSelector(
  fromFeature.getAccountState,
  state => state && state.error
);
