import { Component, ChangeDetectionStrategy } from '@angular/core';

import { AnchorService } from 'src/app/services/anchor.service';

@Component({
  selector: 'od-more-value-propositions',
  templateUrl: './more-value-propositions.component.html',
  styleUrls: ['./more-value-propositions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MoreValuePropositionsComponent {
  constructor(public anchorService: AnchorService) {}
}
