import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { Account } from '../models';

@Injectable({
  providedIn: 'root'
})
export class AccountsService {
  constructor(private http: HttpClient) {}
  signUp(account: Account): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}/api/accounts/marketing_sign_up.json`,
      account
    );
  }
}
