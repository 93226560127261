import { Component, ChangeDetectionStrategy } from '@angular/core';

import { Intercom } from 'ng-intercom';
import { AnchorService } from 'src/app/services/anchor.service';

@Component({
  selector: 'od-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PricingComponent {
  constructor(
    private intercom: Intercom,
    public anchorService: AnchorService
  ) {}

  chat() {
    this.intercom.show();
  }
}
