import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';

export const getRouterUrl = createSelector(
  fromFeature.getRouterState,
  state => state && state.url
);

export const getRouterParams = createSelector(
  fromFeature.getRouterState,
  state => state && state.params
);

export const getRouterQueryParams = createSelector(
  fromFeature.getRouterState,
  state => state && state.queryParams
);

export const getRouterData = createSelector(
  fromFeature.getRouterState,
  state => state && state.data
);
