import { Directive, ElementRef, Input, AfterViewInit } from '@angular/core';
import { AnchorService } from '../services/anchor.service';

@Directive({
  selector: '[odAnchor]'
})
export class AnchorDirective implements AfterViewInit {
  @Input() odAnchor: string;
  constructor(private el: ElementRef, private anchorService: AnchorService) {}

  ngAfterViewInit() {
    this.anchorService.addAnchor(this.odAnchor, this.el);
  }
}
