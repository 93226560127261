import { CarouselComponent } from './carousel/carousel.component';
import { ClientShowcaseComponent } from './client-showcase/client-showcase.component';
import { CreationFlowComponent } from './creation-flow/creation-flow.component';
import { FaqComponent } from './faq/faq.component';
import { FooterComponent } from './footer/footer.component';
import { HeroVideoComponent } from './hero-video/hero-video.component';
import { MissionStatementComponent } from './mission-statement/mission-statement.component';
import { MoreValuePropositionsComponent } from './more-value-propositions/more-value-propositions.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { ParticleEmitterComponent } from './particle-emitter/particle-emitter.component';
import { PillSlideshowComponent } from './pill-slideshow/pill-slideshow.component';
import { PricingComponent } from './pricing/pricing.component';
import { SignUpFormComponent } from './sign-up-form/sign-up-form.component';
import { ValuePropositionsComponent } from './value-propositions/value-propositions.component';

export const components = [
  CarouselComponent,
  ClientShowcaseComponent,
  CreationFlowComponent,
  FaqComponent,
  FooterComponent,
  HeroVideoComponent,
  MissionStatementComponent,
  MoreValuePropositionsComponent,
  NavBarComponent,
  ParticleEmitterComponent,
  PillSlideshowComponent,
  PricingComponent,
  SignUpFormComponent,
  ValuePropositionsComponent
];

export * from './carousel/carousel.component';
export * from './client-showcase/client-showcase.component';
export * from './creation-flow/creation-flow.component';
export * from './faq/faq.component';
export * from './footer/footer.component';
export * from './hero-video/hero-video.component';
export * from './mission-statement/mission-statement.component';
export * from './more-value-propositions/more-value-propositions.component';
export * from './nav-bar/nav-bar.component';
export * from './particle-emitter/particle-emitter.component';
export * from './pill-slideshow/pill-slideshow.component';
export * from './pricing/pricing.component';
export * from './sign-up-form/sign-up-form.component';
export * from './value-propositions/value-propositions.component';
