import {
  trigger,
  state,
  style,
  animate,
  transition,
  keyframes
} from '@angular/animations';

export const autoFadeAnimation = trigger('autoFade', [
  state('void', style({ opacity: 0 })),
  transition('void => *', animate('0.5s ease'))
]);

export const fadeAnimation = trigger('fade', [
  state('active', style({ opacity: 1 })),
  state('inactive', style({ opacity: 0 })),
  transition('inactive => active', animate('0.5s ease'))
]);

export const slideInAnimation = trigger('slideIn', [
  transition('* => active', [
    style({ opacity: 0, transform: 'translateY(-5%)' }),
    animate('0.25s ease', style({ opacity: 1, transform: 'translateY(0%)' }))
  ])
]);

export const slideshowAnimation = trigger('slideshow', [
  state('active', style({ display: 'block' })),
  state('inactive', style({ display: 'none' })),
  transition(
    'active => inactive',
    animate(
      '0.5s ease-in',
      style({ opacity: 0, transform: 'translateY(-200%)' })
    )
  ),
  transition('inactive => active', [
    style({ opacity: 0, transform: 'translateY(50%)' }),
    animate('0.5s ease-out', style({ opacity: 1, transform: 'translateY(0%)' }))
  ])
]);

export const particleAnimation = trigger('particle', [
  transition(':enter', [
    style({
      opacity: 0,
      transform: 'translateY(0%) scale(0)'
    }),
    animate(
      '2.2s',
      keyframes([
        style({
          opacity: 0,
          transform: 'translateY(0%) scale(0)',
          offset: 0
        }),
        style({
          opacity: 1,
          transform: 'translateY(-100%) scale(1)',
          offset: 0.2
        }),
        style({
          opacity: 1,
          transform: 'translateY(-200%) scale(1.2)',
          offset: 0.4
        }),
        style({
          opacity: 1,
          transform: 'translateY(-300%) scale(0.8)',
          offset: 0.6
        }),
        style({
          opacity: 1,
          transform: 'translateY(-400%) scale(1)',
          offset: 0.8
        }),
        style({
          opacity: 0,
          transform: 'translateY(-500%) scale(0)',
          offset: 1
        })
      ])
    )
  ])
]);
