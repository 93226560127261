import { Component, ChangeDetectionStrategy } from '@angular/core';

import { Intercom } from 'ng-intercom';

import { AnchorService } from 'src/app/services/anchor.service';

@Component({
  selector: 'od-mission-statement',
  templateUrl: './mission-statement.component.html',
  styleUrls: ['./mission-statement.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MissionStatementComponent {
  constructor(
    private intercom: Intercom,
    public anchorService: AnchorService
  ) {}

  chat() {
    this.intercom.showNewMessage('Hello, I want to schedule a demo of Odeum.');
  }
}
