import { Component, ChangeDetectionStrategy } from '@angular/core';

import { AnchorService } from 'src/app/services/anchor.service';
import { slideInAnimation } from 'src/app/util/animation.util';

@Component({
  selector: 'od-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [slideInAnimation]
})
export class NavBarComponent {
  active: boolean;

  constructor(public anchorService: AnchorService) {}
}
