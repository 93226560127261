import {
  Component,
  ChangeDetectionStrategy,
  HostListener,
  OnInit,
  ViewChild,
  ElementRef
} from '@angular/core';

import { Subject, interval, merge } from 'rxjs';
import { filter, scan, delay, pluck } from 'rxjs/operators';

import { particleAnimation } from '../../util/animation.util';

interface Particle {
  id: number;
  src: string;
  left: number;
  bottom: number;
}

@Component({
  selector: 'od-particle-emitter',
  templateUrl: './particle-emitter.component.html',
  styleUrls: ['./particle-emitter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [particleAnimation]
})
export class ParticleEmitterComponent implements OnInit {
  @ViewChild('spawn') spawn: ElementRef;
  add$ = new Subject<Particle>();
  remove$ = this.add$.pipe(
    delay(2000),
    pluck('id')
  );
  particles$ = merge(this.add$, this.remove$).pipe(
    scan((acc: Particle[], cur: Particle | number) => {
      if (typeof cur === 'number') {
        return acc.filter(particle => particle.id !== cur);
      } else {
        return [...acc, cur];
      }
    }, [])
  );
  emitter$ = interval(1000).pipe(filter(() => this.canEmit));
  canEmit = false;
  id = 1;
  people = [
    'assets/img/people/charmeling.jpg',
    'assets/img/people/cstevens.jpg',
    'assets/img/people/dfordyce.jpg',
    'assets/img/people/jboepple.jpg',
    'assets/img/people/jdysart.jpg',
    'assets/img/people/jmayes.jpg',
    'assets/img/people/ldaniels.jpg',
    'assets/img/people/mstratford.jpg',
    'assets/img/people/mviesca.jpg',
    'assets/img/people/tfreeman.jpg',
    'assets/img/people/tpastell.jpg'
  ];
  personIndex = Math.floor(Math.random() * this.people.length);

  ngOnInit() {
    this.emitter$.subscribe(val => {
      this.emit();
    });
  }

  @HostListener('mouseenter') onMouseEnter() {
    this.canEmit = true;
    this.emit();
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.canEmit = false;
  }

  emit() {
    const { x, y } = this.randomLocation;
    const particle = {
      id: this.id++,
      src: this.nextPerson,
      left: x,
      bottom: y
    };
    this.add$.next(particle);
  }

  private get nextPerson(): string {
    this.personIndex++;
    if (this.personIndex > this.people.length - 1) {
      this.personIndex = 0;
    }
    return this.people[this.personIndex];
  }

  private get randomLocation(): { x: number; y: number } {
    const { width, height } = this.spawn.nativeElement.getBoundingClientRect();
    const x = Math.floor(Math.random() * width);
    const y = Math.floor(Math.random() * height);
    return { x, y };
  }
}
