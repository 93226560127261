import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { Store } from '@ngrx/store';

import * as fromStore from '../../store';
import { Observable } from 'rxjs';
import { autoFadeAnimation } from 'src/app/util/animation.util';
import { Intercom } from 'ng-intercom';

@Component({
  selector: 'od-sign-up-form',
  templateUrl: './sign-up-form.component.html',
  styleUrls: ['./sign-up-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [autoFadeAnimation]
})
export class SignUpFormComponent implements OnInit {
  loaded$: Observable<boolean>;
  loading$: Observable<boolean>;
  message$: Observable<string>;
  error$: Observable<any>;
  accountForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    name: ['', Validators.required],
    agree: [false, Validators.requiredTrue]
  });
  messageDismissed = false;
  errorDismissed = false;
  constructor(private store: Store<any>, private fb: FormBuilder, private intercom: Intercom) {}

  ngOnInit() {
    this.loaded$ = this.store.select(fromStore.getAccountLoaded);
    this.loading$ = this.store.select(fromStore.getAccountLoading);
    this.message$ = this.store.select(fromStore.getAccountMessage);
    this.error$ = this.store.select(fromStore.getAccountError);
  }

  onSubmit() {
    const account = this.accountForm.value;
    account.visitorId = this.intercom.getVisitorId();
    this.store.dispatch(new fromStore.AccountSignUp({ account }));
    this.errorDismissed = false;
  }
}
