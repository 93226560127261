import { Component, ChangeDetectionStrategy } from '@angular/core';

import { interval } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { slideshowAnimation } from 'src/app/util/animation.util';

@Component({
  selector: 'od-pill-slideshow',
  templateUrl: './pill-slideshow.component.html',
  styleUrls: ['./pill-slideshow.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [slideshowAnimation]
})
export class PillSlideshowComponent {
  active$ = interval(2000).pipe(
    startWith(3),
    map(val => {
      switch (val % 4) {
        default:
        case 0:
          return 'first';
        case 1:
          return 'second';
        case 2:
          return 'third';
        case 3:
          return 'forth';
      }
    })
  );
}
