import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';

import { fadeAnimation } from 'src/app/util/animation.util';

declare const jwplayer: any;

@Component({
  selector: 'od-hero-video',
  templateUrl: './hero-video.component.html',
  styleUrls: ['./hero-video.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeAnimation]
})
export class HeroVideoComponent implements OnInit {
  videoActive: boolean;
  player: any;

  ngOnInit() {
    this.player = jwplayer('player').setup({
      playlist: 'https://cdn.jwplayer.com/v2/media/LEqEcNqA'
    });
  }

  showVideo() {
    this.videoActive = true;
    this.player.play();
  }

  hideVideo() {
    this.videoActive = false;
    this.player.stop();
  }

}
