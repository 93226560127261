import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as fromRouterStore from '@ngrx/router-store';
import * as fromRouter from './router.reducer';
import * as fromAccount from './account.reducer';

export interface State {
  router: fromRouterStore.RouterReducerState<fromRouter.State>;
  account: fromAccount.State;
}

export const reducers: ActionReducerMap<State> = {
  router: fromRouterStore.routerReducer,
  account: fromAccount.reducer
};

export const getRouterState = createFeatureSelector<fromRouter.State>('router');
export const getAccountState = createFeatureSelector<fromAccount.State>('account');

export * from './router.reducer';
