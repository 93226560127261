import { Action } from '@ngrx/store';
import { Account } from '../../models';

export enum ActionTypes {
  SignUp = '[Account] Sign Up',
  SignUpFail = '[Account] Sign Up Fail',
  SignUpSuccess = '[Account] Sign Up Success'
}

export class AccountSignUp implements Action {
  readonly type = ActionTypes.SignUp;
  constructor(public payload: { account: Account }) {}
}

export class AccountSignUpFail implements Action {
  readonly type = ActionTypes.SignUpFail;
  constructor(public payload: { error: any }) {}
}

export class AccountSignUpSuccess implements Action {
  readonly type = ActionTypes.SignUpSuccess;
  constructor(public payload: { message: string }) {}
}

export type AccountAction =
  | AccountSignUp
  | AccountSignUpFail
  | AccountSignUpSuccess;
