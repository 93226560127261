import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';

import { environment } from 'src/environments/environment';

import { Intercom } from 'ng-intercom';

@Component({
  selector: 'od-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {
  constructor(private intercom: Intercom) {}

  ngOnInit() {
    this.intercom.boot({
      app_id: environment.intercomAppId,
      vertical_padding: 70
    });
  }
}
