import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';

import {
  StoreRouterConnectingModule,
  RouterStateSerializer
} from '@ngrx/router-store';
import { StoreModule, MetaReducer } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { IntercomModule } from 'ng-intercom';

import { AppComponent } from './app.component';
import { environment } from 'src/environments/environment';

import { reducers, effects, CustomSerializer } from './store';
import * as fromPages from './pages';
import * as fromComponents from './components';
import * as fromDirectices from './directives';
import * as fromServices from './services';

const routes: Routes = [{ path: '', component: fromPages.OverviewComponent }];

@NgModule({
  declarations: [
    AppComponent,
    ...fromPages.components,
    ...fromComponents.components,
    ...fromDirectices.directives
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(routes),
    HttpClientModule,
    ReactiveFormsModule,
    StoreModule.forRoot(reducers),
    EffectsModule.forRoot(effects),
    StoreRouterConnectingModule.forRoot(),
    StoreDevtoolsModule.instrument(),
    IntercomModule.forRoot({
      appId: environment.intercomAppId,
      updateOnRouterChange: true
    })
  ],
  providers: [
    { provide: RouterStateSerializer, useClass: CustomSerializer },
    ...fromServices.services
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
