import * as fromActions from '../actions/account.actions';

export interface State {
  message: string;
  loaded: boolean;
  loading: boolean;
  error: any;
}

export const initialState: State = {
  message: '',
  loaded: false,
  loading: false,
  error: undefined
};

export function reducer(
  state = initialState,
  action: fromActions.AccountAction
): State {
  switch (action.type) {
    case fromActions.ActionTypes.SignUp: {
      return { ...state, loading: true, loaded: false, error: undefined };
    }

    case fromActions.ActionTypes.SignUpFail: {
      const { error } = action.payload;
      return { ...state, loading: false, loaded: false, error };
    }

    case fromActions.ActionTypes.SignUpSuccess: {
      const { message } = action.payload;
      return {
        ...state,
        message,
        loading: false,
        loaded: true,
        error: undefined
      };
    }

    default:
      return state;
  }
}
