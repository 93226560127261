import {
  Component,
  ChangeDetectionStrategy,
  ContentChildren,
  QueryList,
  AfterContentInit
} from '@angular/core';
import { CarouselPaneDirective } from 'src/app/directives/carousel-pane.directive';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'od-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CarouselComponent implements AfterContentInit {
  @ContentChildren(CarouselPaneDirective)
  panes: QueryList<CarouselPaneDirective>;
  active = 0;
  swipePosition: { clientX: number; clientY: number };

  constructor(private sanitizer: DomSanitizer) {}

  ngAfterContentInit() {}

  get slideTransformation() {
    return this.sanitizer.bypassSecurityTrustStyle(
      `translateX(${this.active * -100}%)`
    );
  }

  get canMoveLeft(): boolean {
    return this.active > 0;
  }

  get canMoveRight(): boolean {
    return this.active < this.panes.length - 1;
  }

  moveToPane(index) {
    this.active = index;
  }

  moveLeft() {
    if (this.canMoveLeft) {
      this.active--;
    }
  }

  moveRight() {
    if (this.canMoveRight) {
      this.active++;
    }
  }

  swipe(event: TouchEvent, when: string) {
    const { clientX, clientY } = event.changedTouches[0];
    switch (when) {
      case 'start': {
        this.swipePosition = { clientX, clientY };
        break;
      }
      case 'end': {
        const dy = this.swipePosition.clientY - clientY;
        const dx = this.swipePosition.clientX - clientX;
        if (Math.abs(dx) > 30 && Math.abs(dx) > Math.abs(dy)) {
          if (dx < 0) {
            this.moveLeft();
          } else {
            this.moveRight();
          }
        }
      }
    }
  }
}
