import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Params,
  Data
} from '@angular/router';

import * as fromRouterStore from '@ngrx/router-store';

export interface State {
  url: string;
  queryParams: Params;
  params: Params;
  data: Data;
}

export class CustomSerializer
  implements fromRouterStore.RouterStateSerializer<State> {
  serialize(routerState: RouterStateSnapshot): State {
    const { url } = routerState;
    const { queryParams } = routerState.root;
    let { params } = routerState.root;

    let state: ActivatedRouteSnapshot = routerState.root;
    while (state.firstChild) {
      state = state.firstChild;
      params = { ...params, ...state.params };
    }
    const { data } = state;

    return { url, queryParams, params, data };
  }
}
